import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-49339ed7"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["name", "value", "id", "checked", "disabled"];
const _hoisted_2 = ["for"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("input", {
            type: "radio",
            class: "custom-control-input {{ trackingClass }}",
            name: _ctx.name,
            value: _ctx.value,
            id: _ctx.id,
            checked: _ctx.checked,
            disabled: _ctx.disabled,
            onChange: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.upstreamChange && _ctx.upstreamChange(...args)))
        }, null, 40, _hoisted_1),
        _createElementVNode("label", {
            for: _ctx.id,
            class: "custom-control-label green-radio"
        }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
        ], 8, _hoisted_2)
    ], 64));
}
