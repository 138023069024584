import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import SwitchToggle from '@/components/Base/Forms/Inputs/SwitchToggle.vue';
export default defineComponent({
    name: 'checkout-newsletter-subscription',
    components: {
        SwitchToggle,
    },
    props: {
        checkout_type: {
            required: true,
            type: String,
        },
    },
    methods: Object.assign(Object.assign(Object.assign({}, mapActions('blue', {
        registerBlueNewsletter: 'SET_REGISTER_NEWSLETTER',
    })), mapActions('red', {
        registerRedNewsletter: 'SET_REGISTER_NEWSLETTER',
    })), { registerForNewsletter(register) {
            if (this.checkout_type === 'red') {
                this.registerRedNewsletter(register);
            }
            else if (this.checkout_type === 'blue') {
                this.registerBlueNewsletter(register);
            }
        } }),
    computed: Object.assign(Object.assign(Object.assign(Object.assign({}, mapGetters('account', { hasNewsletter: 'hasNewsletter', isLogin: 'isLogin' })), mapState('blue', { blueCart: 'cart' })), mapState('red', { redCart: 'cart' })), { shouldRegisterNewsletter: {
            get: function () {
                var _a, _b, _c, _d;
                if (this.checkout_type === 'red') {
                    return (_b = (_a = this.redCart) === null || _a === void 0 ? void 0 : _a.register_newsletter) !== null && _b !== void 0 ? _b : false;
                }
                if (this.checkout_type === 'blue') {
                    return (_d = (_c = this.blueCart) === null || _c === void 0 ? void 0 : _c.register_newsletter) !== null && _d !== void 0 ? _d : false;
                }
                return false;
            },
            set: function (register) {
                this.registerForNewsletter(register);
            },
        }, showNewsletterRegistration() {
            if (!this.isLogin) {
                return true;
            }
            return !this.hasNewsletter;
        } }),
});
