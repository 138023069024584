import { defineComponent } from 'vue';
import { ProductCondition } from '@/models/red/ProductEvaluation';
export default defineComponent({
    name: 'accessory-check',
    props: {
        current_step: { type: Number },
        user_evaluation: {
            type: Object,
            required: true,
        },
    },
    emits: ['emitProductEquipmentComplete', 'emitProductOriginalPackaging'],
    methods: {
        packagingCheck(value) {
            this.$emit('emitProductOriginalPackaging', value);
        },
        accessoryCheck(value) {
            this.$emit('emitProductEquipmentComplete', value);
        },
    },
    computed: {
        hintOvp() {
            if (this.user_evaluation.condition === ProductCondition.NEW) {
                return 'Bei Zustand NEU setzen wir voraus, dass die OVP vorhanden ist';
            }
            if (this.user_evaluation.condition === ProductCondition.MINT) {
                return 'Bei Zustand WIE NEU setzen wir voraus, dass die OVP vorhanden ist';
            }
            return null;
        },
        hintAccessory() {
            if (this.user_evaluation.condition === ProductCondition.NEW) {
                return 'Bei Zustand NEU setzen wir voraus, dass das Originalzubehör vorhanden ist';
            }
            return null;
        },
    },
});
