import { defineComponent } from 'vue';
export default defineComponent({
    name: 'dude-image',
    props: {
        current_step: Number,
    },
    data() {
        return {
            dude_image1_path: '/images/_new_brand/sticker_swoopItCupid.png',
            dude_image1_alt: 'Wie ist der Zustand Deiner Ware?',
            dude_style1: 'margin: 0 0 70px 80px',
            dude_image2_path: '/images/_new_brand/sticker_swoopItCupid.png',
            dude_image2_alt: 'Hast Du die Originalverpackung und das Zubehör?',
            dude_style2: 'margin: 0 0 30px 80px',
            dude_image3_path: '/images/_new_brand/sticker_swoopItCupid.png',
            dude_image3_alt: 'Abschicken und abkassieren!',
            dude_style3: 'margin: 0 0 30px 80px',
        };
    },
    computed: {
        imagePath() {
            if (this.current_step === 1) {
                return this.dude_image1_path;
            }
            else if (this.current_step === 2 || this.current_step == 3) {
                return this.dude_image2_path;
            }
            else {
                return this.dude_image3_path;
            }
        },
        imageAltText() {
            if (this.current_step === 1) {
                return this.dude_image1_alt;
            }
            else if (this.current_step === 2 || this.current_step == 3) {
                return this.dude_image2_alt;
            }
            else {
                return this.dude_image3_alt;
            }
        },
        imageStyle() {
            if (this.current_step === 1) {
                return this.dude_style1;
            }
            else if (this.current_step === 2) {
                return this.dude_style2;
            }
            else {
                return this.dude_style3;
            }
        },
    },
});
