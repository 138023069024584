import { defineComponent } from 'vue';
export default defineComponent({
    name: 'back-button',
    emits: ['emit-step-back'],
    methods: {
        stepBackLink() {
            this.$emit('emit-step-back');
        },
    },
});
