export default function isVisible(selector, peek = 0) {
    const element = document.querySelector(selector);
    if (element === null) {
        return false;
    }
    const rectangle = element.getBoundingClientRect();
    const offsets = {
        top: rectangle.top + window.scrollY,
        bottom: rectangle.bottom + window.scrollY,
        left: rectangle.left + window.scrollX,
        right: rectangle.right + window.scrollX,
    };
    const viewportTop = window.scrollY;
    const viewportBottom = viewportTop + window.innerHeight;
    return offsets.bottom + peek > viewportTop && offsets.top - peek < viewportBottom;
}
