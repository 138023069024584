import app from '@/entries/main';
import RedProductCondition from '@/components/Red/RedProductCondition/RedProductCondition.vue';
import RedCheckout from '@/components/Red/RedCheckout/RedCheckout.vue';
import RedCheckoutSuccess from '@/components/Red/RedCheckout/RedCheckoutSuccess.vue';
import RedProductRequestPopup from '@/components/PopUps/RedProductRequestPopup/RedProductRequestPopup.vue';
app.component('red-product-condition', RedProductCondition);
app.component('red-checkout', RedCheckout);
app.component('red-checkout-success', RedCheckoutSuccess);
app.component('red-product-request-popup', RedProductRequestPopup);
// weird ?
require('@legacy/RED_Checkout.js');
require('@legacy/RED_list_and_filter.js');
app.mount('#app');
