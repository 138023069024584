import { defineComponent } from 'vue';
import BaseInput from '@/components/Base/Forms/Inputs/BaseInput.vue';
import { Form } from 'vee-validate';
import { addressRule } from '@/helpers/ValidationRules';
export default defineComponent({
    name: 'address-form',
    components: {
        BaseInput,
        'v-form': Form,
    },
    emits: [
        'update:modelValue',
        'finishChange',
        'change',
        'change:first_name',
        'change:last_name',
        'change:street_name',
        'change:street_number',
        'change:postcode',
        'change:city',
        'change:company',
    ],
    props: {
        id: { type: String, required: true },
        modelValue: {
            type: Object,
            required: true,
        },
        withCompany: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            edit_address: null,
            show_company: false,
            finish_address_timeout: null,
        };
    },
    beforeUnmount() {
        this.resetFinishAddressTimeout();
    },
    computed: {
        current_address() {
            var _a;
            return (_a = this.edit_address) !== null && _a !== void 0 ? _a : this.modelValue;
        },
        first_name: {
            get: function () {
                return this.current_address.first_name;
            },
            set: function (new_value) {
                this.buildEditAddress();
                if (this.edit_address) {
                    this.edit_address.first_name = new_value;
                    this.emitChangeEvent('first_name', new_value);
                }
            },
        },
        firstNameErrors() {
            var _a;
            return this.transformError((_a = this.errors) === null || _a === void 0 ? void 0 : _a.firstname);
        },
        last_name: {
            get: function () {
                return this.current_address.last_name;
            },
            set: function (new_value) {
                this.buildEditAddress();
                if (this.edit_address) {
                    this.edit_address.last_name = new_value;
                    this.emitChangeEvent('last_name', new_value);
                }
            },
        },
        lastNameErrors() {
            var _a;
            return this.transformError((_a = this.errors) === null || _a === void 0 ? void 0 : _a.lastname);
        },
        street_name: {
            get: function () {
                return this.current_address.street_name;
            },
            set: function (new_value) {
                this.buildEditAddress();
                if (this.edit_address) {
                    this.edit_address.street_name = new_value;
                    this.emitChangeEvent('street_name', new_value);
                }
            },
        },
        street_number: {
            get: function () {
                return this.current_address.street_number;
            },
            set: function (new_value) {
                this.buildEditAddress();
                if (this.edit_address) {
                    this.edit_address.street_number = new_value;
                    this.emitChangeEvent('street_number', new_value);
                }
            },
        },
        streetNameErrors() {
            var _a;
            return this.transformError((_a = this.errors) === null || _a === void 0 ? void 0 : _a.street_name);
        },
        streetNumberErrors() {
            var _a;
            return this.transformError((_a = this.errors) === null || _a === void 0 ? void 0 : _a.street_number);
        },
        postcode: {
            get: function () {
                return this.current_address.postcode;
            },
            set: function (new_value) {
                this.buildEditAddress();
                if (this.edit_address) {
                    this.edit_address.postcode = new_value;
                    this.emitChangeEvent('postcode', new_value);
                }
            },
        },
        postCodeErrors() {
            var _a;
            return this.transformError((_a = this.errors) === null || _a === void 0 ? void 0 : _a.postcode);
        },
        city: {
            get: function () {
                return this.current_address.city;
            },
            set: function (new_value) {
                this.buildEditAddress();
                if (this.edit_address) {
                    this.edit_address.city = new_value;
                    this.emitChangeEvent('city', new_value);
                }
            },
        },
        cityErrors() {
            var _a;
            return this.transformError((_a = this.errors) === null || _a === void 0 ? void 0 : _a.city);
        },
        company: {
            get: function () {
                var _a;
                return (_a = this.current_address.company) !== null && _a !== void 0 ? _a : '';
            },
            set: function (new_value) {
                this.buildEditAddress();
                if (this.edit_address) {
                    this.edit_address.company = new_value;
                    this.emitChangeEvent('company', new_value);
                }
            },
        },
        companyErrors() {
            var _a;
            return this.transformError((_a = this.errors) === null || _a === void 0 ? void 0 : _a.company);
        },
        validationSchema() {
            return addressRule;
        },
        showCompanyField() {
            return this.withCompany && (this.show_company || this.current_address.company !== '');
        },
    },
    methods: {
        buildEditAddress() {
            if (!this.edit_address) {
                this.edit_address = Object.assign({}, this.modelValue);
            }
        },
        onFocusOutForm() {
            if (!this.edit_address) {
                return;
            }
            this.$emit('update:modelValue', Object.assign({}, this.edit_address));
        },
        createFinishAddressTimeout(renew) {
            if (this.finish_address_timeout !== null) {
                if (!renew) {
                    return;
                }
                this.resetFinishAddressTimeout();
            }
            this.finish_address_timeout = window.setTimeout(this.finishAddressChange, 1000);
        },
        finishAddressChange() {
            if (!this.edit_address) {
                return;
            }
            this.$emit('finishChange', Object.assign({}, this.edit_address));
            this.finish_address_timeout = null;
        },
        resetFinishAddressTimeout() {
            if (this.finish_address_timeout) {
                window.clearTimeout(this.finish_address_timeout);
                this.finish_address_timeout = null;
            }
        },
        onBlurFormField() {
            this.createFinishAddressTimeout(true);
        },
        emitChangeEvent(name, new_value) {
            this.createFinishAddressTimeout(true);
            this.$emit('change');
            this.$emit(`change:${name}`, new_value);
        },
        toggleShowCompany() {
            this.show_company = !this.show_company;
        },
        transformError(error) {
            if (typeof error === 'undefined') {
                return undefined;
            }
            if (!error) {
                return undefined;
            }
            return typeof error === 'string' ? [error] : error;
        },
    },
    watch: {
        modelValue() {
            if (this.finish_address_timeout === null) {
                this.edit_address = null;
            }
        },
    },
});
