import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "mb-1 pt-md-0 w-100" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_base_input = _resolveComponent("base-input");
    const _component_checkbox_input = _resolveComponent("checkbox-input");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_base_input, {
            id: "locationSearchInput",
            inputType: "search",
            name: "search",
            "model-value": "",
            placeholder: "Nach PLZ suchen",
            rules: _ctx.rule,
            onChanged: _ctx.searchInput
        }, null, 8, ["rules", "onChanged"]),
        _createVNode(_component_checkbox_input, {
            value: "opened",
            id: "checkbox_opened_stores",
            name: "only_opened_stores",
            text: "Jetzt geöffnet",
            "onUpdate:modelValue": _ctx.checkboxChanged
        }, null, 8, ["onUpdate:modelValue"])
    ]));
}
