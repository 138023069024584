import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import LocationsMapStorePayoutSelection from '@/components/Red/RedCheckout/components/LocationsMapStorePayoutSelection.vue';
import LocationStoreSelected from '@/components/Red/RedCheckout/components/LocationStoreSelected.vue';
import { PayoutType } from '@/models/red/PayoutOption';
import RadioInput from '@/components/Base/Forms/Inputs/RadioInput.vue';
export default defineComponent({
    name: 'local-store-selection',
    components: {
        RadioInput,
        LocationsMapStorePayoutSelection,
        LocationStoreSelected,
    },
    methods: Object.assign({ resetShop() {
            this.setLocalStoreTargetLocation({ target_location_id: 0 });
        } }, mapActions('red', {
        setLocalStoreTargetLocation: 'SET_LOCAL_STORE_PAYOUT',
    })),
    computed: Object.assign(Object.assign(Object.assign({}, mapState('red', {
        cart: 'cart',
    })), { selectedLocationId() {
            var _a;
            return ((_a = this.cart) === null || _a === void 0 ? void 0 : _a.payout_option.type) === PayoutType.localStore
                ? this.cart.payout_option.target_location_id
                : null;
        } }), mapGetters('account', {
        isLogin: 'isLogin',
    })),
});
