import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    class: "row no-gutters customCheckbox",
    style: {}
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_checkbox = _resolveComponent("v-checkbox");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_checkbox, {
            modelValue: _ctx.inputValue,
            "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event) => ((_ctx.inputValue) = $event)),
                _ctx.handleUpdates
            ],
            "true-value": true,
            label: _ctx.text,
            color: "#4BE000",
            value: _ctx.text,
            density: "compact",
            "hide-details": ""
        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "value"])
    ]));
}
