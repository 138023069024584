import eventBus from '@/services/EventBus';
import {redCartApiClient} from '@/services/api';
import Cart from '@/models/red/Cart';

$(() => {

   /* SELLBOX ******************************************************* */

    $(document).on('click', '.JS-SellboxlinkEditArticle', function (e) {
        e.preventDefault();
        console.log('Artikel Edit');
        handleSellboxItemEdit($(this));
    });

    $(document).on('click', '.JS-SellboxlinkDeleteArticle', function (e) {
        e.preventDefault();
        removeFromSellBoxWithConfirm($(this));
    });

    function handleSellboxItemEdit($element) {
        var product_id = $element.attr('data-base-id');
        var replacement = $element.attr('data-id');
        var slug = $element.attr('data-slug');
        window.location = '/verkaufen/bewertung/' + slug + '__' + product_id + '/' + replacement;
    }


    function removeFromSellBoxWithConfirm($element) {
        const productTitle = $element.data('title');
        const productUUID = $element.attr('data-id');


        // NEUES POPUP::::
        eventBus.emit('openInfoPopup', {
            icon: 'warning',
            headline: 'Artikel wirklich entfernen?',
            message: productTitle,
            persistent: true,

            buttonConfirmText: 'Jetzt entfernen',
            buttonConfirmFunction: () => {

                eventBus.emit('closeInfoPopup');

                window
                    .useStore()
                    .dispatch('red/REMOVE_FROM_CART', { product_uuid: productUUID })
                    .then((response) => {
                        $(`.JS_removable_red_item.JS_item_${productUUID}`).each(function () {
                            $(this).remove();
                        });
                        if(response.cart.products.length === 0 && window.location.href.includes('/verkaufen/checkout')) {
                            window.location.replace('/verkaufen');
                            return;
                        }
                        updateRedPayoutDisplay(response);
                    });
            },

            buttonDenyText: 'Abbrechen',
            buttonDenyFunction: () => {
                eventBus.emit('closeInfoPopup');
            },
        });

    }


    /* Price Update - Versandbox vor Checkout UND Popup mit Verkaufsprodukten ****************************** */
    function updateRedPayoutDisplay(data) {


        $('.JS_sellbox_total, .JS_sellbox_mypoints').text(Math.floor(data.cart.total));

        const totalSellPrice = $('.JS_total, .JS_sellbox_total');

        if(data.cart.payout_option?.type =="API-PAYPAL"){
            totalSellPrice.text(formatPrice(data.cart.paypal_total, '€'));
        } else {
            totalSellPrice.text(formatPrice(data.cart.cash_total, '€'));
        }
        $('.JS_cash').text(formatPrice(data.cart.cash_total, '€'));
    }
});