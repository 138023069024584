var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent } from 'vue';
import { locationsApiClient } from '@/services/api';
import OpeningTimesBlock from '@/components/Locations/subComponents/OpeningTimesBlock.vue';
import GoogleMapsNavigationButton from '@/components/Locations/subComponents/GoogleMapsNavigationButton.vue';
import slugify from '@/services/formattter/slugify';
import eventBus from '@/services/EventBus';
export default defineComponent({
    name: 'location-card',
    components: {
        OpeningTimesBlock,
        GoogleMapsNavigationButton,
    },
    props: {
        location: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loaded_location: null,
            opened: false,
            visible: true,
        };
    },
    computed: {
        currentLocation() {
            var _a;
            return (_a = this.loaded_location) !== null && _a !== void 0 ? _a : this.location;
        },
        link() {
            return `/stores/${slugify(this.location.name)}--${this.location.id}`;
        },
    },
    methods: {
        loadAdditionalShopData() {
            locationsApiClient.queryLocation(this.location.id).then((location) => {
                if (!location) {
                    return;
                }
                this.loaded_location = location;
            });
        },
        toggleDisplay() {
            if (this.opened) {
                this.opened = false;
                return;
            }
            eventBus.emit('googleMaps:mapLocationClicked', this.location.id);
        },
    },
    mounted() {
        eventBus.on('googleMaps:mapLocationClicked', (locationId) => __awaiter(this, void 0, void 0, function* () {
            if (this.location.id === locationId) {
                this.opened = true;
                const card = this.$refs.self;
                if (card === null || !(card.parentNode instanceof HTMLElement)) {
                    return;
                }
                // load opening times if not yet present, synchronous to prevent scroll-fail
                if (this.currentLocation.opening_times.length === 0) {
                    yield this.loadAdditionalShopData();
                }
                // scroll current location card to top
                card.parentNode.scrollTop = card.offsetTop - card.parentNode.offsetTop - 5;
                return;
            }
            this.opened = false;
        }));
        eventBus.on('googleMaps:zoomedToLocations', (visibleLocations) => {
            if (Array.isArray(visibleLocations)) {
                this.visible = visibleLocations.includes(this.location.id);
            }
        });
    },
});
