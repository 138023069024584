import eventBus from '@/services/EventBus';

function getRedModelIdForFilter() {
    return $('input.JS_RED_MODEL_ID').val();
}


function handleRedAttributeSelection(payload, initiator) {
    $.post('/verkaufen/filter/' + getRedModelIdForFilter(), {attributes: payload}).done(function (data) {
        $('.ProductWrapper').hide();
        let iter = 0;
        $(data.products).each(function (i, id) {
            $('#' + id).fadeIn();
            if (id !== 'request') {
                iter++;
            }
        });
        $('#request').fadeIn();

        if (iter == 1) {
            $('.ProductWrapper:last-of-type.mobileView').siblings().css('min-width', '100%');
        } else {
            $('.ProductWrapper').css('min-width', '');
        }

        $('.JS_anzahlTreffer').html(iter);
        checkAttributeButtons(data.attributes, initiator);
    });
}

// ATTRIBUTE DIE NOCH VERFÜGBAR SIND ****************************************
function checkAttributeButtons(attributes, initiator) {
    if (Object.keys(attributes).length === 0 && typeof initiator === 'object') {

        eventBus.emit('openInfoPopup', {
            item: 'warning',
            headline: 'Dein Gerät ist uns mit dieser Auswahl nicht bekannt.',
            message: 'Bitte ändere die Filterauswahl oder setze die Filter zurück.',

            buttonConfirmText: 'Filter zurücksetzen',
            buttonConfirmFunction: () => {
                console.log('Filter zurücksetzen');
                eventBus.emit('closeInfoPopup');

                $('.JS_fancyOptionField').each(function (i, e) {
                    resetSelectField($(e));
                });
                $('.JS_attributeBlogWithHeadline').each(function (i, e) {
                    resetRedAttributesBlock($(e));
                });
                setTimeout(function () {
                    window.scrollTo(0, 0);
                }, 1000);
                handleRedAttributeSelection({}, initiator);

                setTimeout(() => {
                    eventBus.emit('openInfoPopup', {
                        icon: 'success',
                        headline: 'Filter reset',
                        message: 'Die Filter wurden zurückgesetzt',
                        timer: 2000
                    });
                }, 100)

            },
            buttonDenyText: 'OK',
            buttonDenyFunction: () => {
                console.log('OK');
                eventBus.emit('closeInfoPopup');
            },

        })
        return;
    }

    let $wrapper = $('.attributesWrapper');
    let $fancyselects = $('.JS_allFancySelectFields');

    // Alle Buttons auf Farbe DEFAULT:
    $('.JS_RedAttributeButtonAction').removeClass('JS_availableAttribute').removeClass('JS_notAvailableAttribute');

    // FANCY Option fields auf "NICHT VERFÜGBAR" setzen
    $fancyselects.find($('.JS_fancyOptionField')).addClass('JS_notAvailableFancyOptionField');

    // Alle Attribute Buttons auf "Nicht Verfügbar" setzten
    $wrapper.find($('.JS_RedAttributeButtonAction')).addClass('JS_notAvailableAttribute');

    let countvariants = 0;
    $.each(attributes, function (i, e) {
        countvariants += e.length;

        $.each(e, function (i, e) {
            // Attribute Buttons die OK sind auf "verfügbar" setzen und "Nicht verfügbar" Class entfernen
            $wrapper
                .find($('.JS_RedAttributeButtonAction[datavalue=\'' + e.attribute_value + '\']'))
                .removeClass('JS_notAvailableAttribute')
                .addClass('JS_availableAttribute');

            // FANCY Option fields kennzeichnen die OK sind
            $fancyselects
                .find($('.JS_fancyOptionField[datavalue="' + e.attribute_value + '"]'))
                .removeClass('JS_notAvailableFancyOptionField')
                .addClass('JS_availableFancyOptionField');
        });
    });

    // Scroll into view if single variant was found
    if (Object.keys(attributes).length === countvariants) {
        // Schließe alle Option Wrapper
        $fancyselects.find($('.JS_fancyOptionsWrapper')).addClass('d-none');

        scrollToAnker('.JS_headlineSelectProduct');
    }

    // Wenn nichts ausgewählt ist, dann alle Buttons wieder auf Farbe DEFAULT
    if ($('.JS_buttonDeleteSelected[style="display: flex;"]').length === 0) {
        $.each($('.JS_RedAttributeButtonAction'), function () {
            $(this).removeClass('JS_availableAttribute').removeClass('JS_notAvailableAttribute');
        });
    }
}

$(document).on('click', '.JS_buttonDeleteSelected', function () {
    resetRedAttributesBlock($(this).parents('.JS_attributeBlogWithHeadline'));

    let payload = {};
    $('.redDeskViewAttrBtns .JS_RedAttributeButtonAction.active').each(function (i, e) {
        payload[$(this).attr('dataname')] = $(this).attr('datavalue');
    });
    handleRedAttributeSelection(payload, $(this));
});

function resetRedAttributesBlock($block) {
    // REMOVE Active Button selection
    $block.find('.JS_RedAttributeButtonAction').removeClass('active');

    // Attribut Blog einblenden
    $block.find('.JS_attributeBlog').removeClass('d-none').addClass('d-flex');

    $block.find('.JS_buttonDeleteSelected').hide();
}

$(document).on('click', '.JS_RedAttributeButtonAction', function () {
    let currentButton = $(this);
    let currentAttrBlog = $(this).parents('.JS_attributeBlogWithHeadline');

    let attribute = currentButton.attr('dataname');
    $('.JS_RedAttributeButtonAction[dataname="' + attribute + '"]').removeClass('active');
    currentButton.addClass('active');

    //Selected Attribut Button einblenden mit gekürztem Text falls nötig
    let buttonText;
    if (currentButton.text().length <= 15) {
        buttonText = currentButton.text();
    } else {
        buttonText = currentButton.text().slice(0, 14) + '..';
    }

    // Auswahl Button (bzw. Auswahl aufheben Button) einblenden
    currentAttrBlog
        .find('.JS_buttonDeleteSelected')
        .css({display: 'flex'})
        .find('.JS_buttonDeleteSelected--text')
        .text(buttonText);

    // Attribut Blog ausblenden
    currentAttrBlog.find('.JS_attributeBlog').removeClass('d-flex').addClass('d-none');

    // Submit Selection
    let payload = {};
    $('.redDeskViewAttrBtns .JS_RedAttributeButtonAction.active').each(function (i, e) {
        payload[$(this).attr('dataname')] = $(this).attr('datavalue');
    });
    handleRedAttributeSelection(payload, $(this));
});


$(() => {

    // Kürzen der Attribut-Button-Texte auf max. 18 Zeichen
    $('.JS_attributeButtonText').each(function (e) {
        if ($(this).html().length > 18) {
            $(this).html($(this).html().slice(0, 16) + '..');
        }
    });


    $('.JS_RedOptionFieldActionWrapper').on('fancyselect.changed', function (event, initiator) {
        // Submit Selection
        let payload = {};
        $('.redMobileViewAttrBtns .JS_RedOptionFieldAction.active').each(function (i, e) {
            payload[$(this).attr('dataname')] = $(this).attr('datavalue');
        });

        handleRedAttributeSelection(payload, initiator);
    });

});
