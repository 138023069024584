import { defineComponent } from 'vue';
export default defineComponent({
    name: 'radio-input',
    props: {
        name: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        trackingClass: {
            type: String,
            required: false,
            default: '',
        },
    },
    emits: ['change'],
    methods: {
        upstreamChange() {
            this.$emit('change');
        },
    },
});
