import { defineComponent } from 'vue';
import OpeningTimePerDay from '@/components/Locations/subComponents/OpeningTimePerDay.vue';
import { ContentLoader } from 'vue-content-loader';
export default defineComponent({
    name: 'opening-times-block',
    components: {
        OpeningTimePerDay,
        ContentLoader,
    },
    props: {
        /** @see https://github.com/vuejs/core/issues/3948#issuecomment-860466204 */
        opening_times: {
            required: true,
            type: null,
            validator: (v) => typeof v === 'object' || v === null,
        },
    },
    methods: {
        openingTimes(day) {
            if (!this.opening_times) {
                return [];
            }
            return this.opening_times.filter((time) => time.day === day);
        },
    },
});
