import { defineComponent } from 'vue';
import eventBus from '@/services/EventBus';
import { CouponType } from '@/models/account/Coupon';
import { mapState } from 'vuex';
export default defineComponent({
    name: 'coupon-usage-block',
    emits: ['loadCoupons'],
    props: {
        coupons: {
            type: Array,
            required: true,
        },
        currentCouponCode: {
            type: String,
            required: true,
        },
        couponType: {
            type: String,
            required: true,
        },
        confirmCouponCodeCallback: {
            type: Function,
            required: true,
        },
        removeCouponCallback: {
            type: Function,
            required: true,
        },
    },
    data: () => {
        return {
            openBlock: false,
            edit_code: null,
        };
    },
    methods: {
        loadCoupons() {
            this.$emit('loadCoupons');
        },
        removeCoupon() {
            return this.removeCouponCallback();
        },
        confirmCouponCode(code) {
            return this.confirmCouponCodeCallback(code);
        },
        applyCouponCode() {
            if (!this.isCodeChanged) {
                return;
            }
            if (this.edit_code == '' && this.currentCouponCode !== '') {
                this.removeCoupon().then(this.resetCouponComponentData);
                return;
            }
            this.confirmCouponCode(this.currentCode).finally(() => {
                if (!this.errorMessage) {
                    this.resetCouponComponentData();
                }
            });
        },
        resetCouponComponentData() {
            this.edit_code = null;
        },
        openCouponOverview() {
            eventBus.emit('openCouponPopup', { type: this.couponType });
        },
        toggleShowBlockContent() {
            this.openBlock = !this.openBlock;
            if (this.openBlock) {
                this.loadCoupons();
            }
        },
    },
    computed: Object.assign(Object.assign(Object.assign({}, mapState('blue', { blueCartErrors: 'cartErrors' })), mapState('red', { redCartErrors: 'cart_errors' })), { errorMessage() {
            var _a, _b;
            if (this.couponType === CouponType.RED) {
                return (_a = this.redCartErrors) === null || _a === void 0 ? void 0 : _a.voucher_code;
            }
            return (_b = this.blueCartErrors) === null || _b === void 0 ? void 0 : _b.voucher_code;
        }, currentCode: {
            get: function () {
                return this.edit_code !== null ? this.edit_code : this.currentCouponCode;
            },
            set: function (code) {
                this.edit_code = code;
                this.openBlock = true;
                if (this.edit_code === this.currentCouponCode) {
                    this.edit_code = null;
                }
            },
        }, showBlockContent() {
            return this.openBlock || (this.currentCode && this.currentCode.length > 0) || this.isCodeChanged;
        },
        isCodeChanged() {
            return this.edit_code !== null && this.edit_code !== this.currentCouponCode;
        },
        hasOwnCoupons() {
            var _a;
            return ((_a = this.coupons) === null || _a === void 0 ? void 0 : _a.length) > 0;
        } }),
    watch: {
        currentCouponCode(currentCode) {
            if (currentCode !== '') {
                this.resetCouponComponentData();
            }
        },
    },
});
