import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import elementHelper from '@/helpers/documentHelper';
import DateInput from '@/components/Base/Forms/Inputs/DateInput.vue';
import RadioInput from '@/components/Base/Forms/Inputs/RadioInput.vue';
import eventBus from '@/services/EventBus';
export default defineComponent({
    name: 'shipping-options',
    components: {
        RadioInput,
        DateInput,
    },
    data() {
        return {
            edit_pickup_date: null,
        };
    },
    methods: Object.assign(Object.assign({}, mapActions('red', {
        set_self_sent_shipping: 'SET_SELF_SENT_SHIPPING',
        set_pickup_shipping: 'SET_PICKUP_SHIPPING',
        getAvailablePickupDates: 'GET_AVAILABLE_PICKUP_DATES',
    })), { savePickupDate(pickupDate) {
            this.set_pickup_shipping({ pickup_date: pickupDate });
        },
        renderModalAbholserviceDetails() {
            eventBus.emit('openInfoPopup', {
                icon: 'info',
                headline: 'Abholservice',
                message: '<div>Der Abholservice ist für dich schon am <strong>nächsten Werktag</strong> verfügbar, sofern du <strong>vor 22:00 Uhr buchst</strong>. <br> <br>Die Abholung erfolgt durch unseren Partner DHL <strong>zwischen 08:00 und 20:00 Uhr</strong>.</div>',
            });
        },
        changeShippingToSelfSend() {
            const element = document.getElementById('RedShippingOptionSelectionBox');
            if (element) {
                elementHelper.resetInvalidCartBorder(element);
            }
            return this.set_self_sent_shipping();
        },
        changeShippingToPickup() {
            const element = document.getElementById('RedShippingOptionSelectionBox');
            if (element) {
                elementHelper.resetInvalidCartBorder(element);
            }
            return this.set_pickup_shipping({ pickup_date: null });
        } }),
    computed: Object.assign(Object.assign({}, mapState('red', {
        cart: 'cart',
        pickupDates: 'pickup_dates',
        shipping_options: 'shipping_options',
    })), { shipping_option() {
            var _a;
            if (!this.cart) {
                return '';
            }
            return (_a = this.cart.shipping_option) === null || _a === void 0 ? void 0 : _a.type;
        }, pickup_date: {
            get: function () {
                var _a, _b;
                return this.edit_pickup_date ? this.edit_pickup_date : (_b = (_a = this.cart) === null || _a === void 0 ? void 0 : _a.shipping_option) === null || _b === void 0 ? void 0 : _b.pickup_date;
            },
            set: function (new_pickup_date) {
                this.edit_pickup_date = new_pickup_date;
            },
        }, pickup_available() {
            var _a;
            return (_a = this.shipping_options) === null || _a === void 0 ? void 0 : _a.PICKUP.is_available;
        },
        self_send_available() {
            var _a;
            return (_a = this.shipping_options) === null || _a === void 0 ? void 0 : _a.SHIP.is_available;
        },
        free_shipping() {
            var _a;
            return (_a = this.shipping_options) === null || _a === void 0 ? void 0 : _a.SHIP.is_free;
        } }),
    mounted() {
        this.getAvailablePickupDates();
    },
});
