import { defineComponent } from 'vue';
import isVisible from '@/services/VisibilityCheck';
export default defineComponent({
    name: 'sticky-cta-row',
    props: {
        target_element: {
            type: String,
            default: '',
        },
        button_text: {
            type: String,
            default: '',
        },
        additional_classes: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            target_in_viewport: false,
        };
    },
    created() {
        window.addEventListener('scroll', this.checkForVisibility);
    },
    methods: {
        checkForVisibility() {
            this.target_in_viewport = isVisible(this.target_element);
        },
        clickStickyCta() {
            const element = document.querySelector(this.target_element);
            if (element !== null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                element.click();
            }
        },
    },
});
