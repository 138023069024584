import { defineComponent } from 'vue';
import SmallProductOverview from '@/components/Red/RedCheckout/components/SmallProductOverview.vue';
import AddressBlock from '@/components/Red/RedCheckout/components/AddressBlock.vue';
import ShippingOptions from '@/components/Red/RedCheckout/components/ShippingOptions.vue';
import InfoPleaseSelectPayoutCondition from '@/components/Red/RedCheckout/components/InfoPleaseSelectPayoutCondition.vue';
import localStoreSelection from '@/components/Red/RedCheckout/components/LocalStoreSelection.vue';
import PayoutConditions from '@/components/Red/RedCheckout/components/PayoutConditions.vue';
import FinalSaleCompletion from '@/components/Red/RedCheckout/components/FinalSaleCompletion.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import CheckoutNewsletterSubscription from '@/components/Base/Newsletter/CheckoutNewsletterSubscription.vue';
import StickyCtaRow from '@/components/Base/StickyCtaRow.vue';
import { PayoutType } from '@/models/red/PayoutOption';
export default defineComponent({
    name: 'red-checkout',
    components: {
        StickyCtaRow,
        CheckoutNewsletterSubscription,
        SmallProductOverview,
        AddressBlock,
        InfoPleaseSelectPayoutCondition,
        ShippingOptions,
        PayoutConditions,
        localStoreSelection,
        FinalSaleCompletion,
    },
    mounted() {
        this.loadCartBag();
        this.loadLocations({
            opened_filter: null,
            region_id: null,
            can_do_local_store_payout: true,
        });
    },
    methods: Object.assign(Object.assign({}, mapActions('red', {
        loadCartBag: 'LOAD_CART_BAG',
    })), mapActions('global', {
        loadLocations: 'LOAD_LOCATIONS',
    })),
    computed: Object.assign(Object.assign(Object.assign(Object.assign({}, mapGetters('account', {
        firstName: 'firstName',
        isLogin: 'isLogin',
    })), mapGetters('global', {
        isMobile: 'isMobile',
    })), mapState('red', {
        cart: 'cart',
        payoutOptions: 'payout_options',
    })), { localStorePayoutEnabled() {
            var _a, _b;
            return (_b = (_a = this.payoutOptions) === null || _a === void 0 ? void 0 : _a.LOCAL_STORE_PAYOUT) === null || _b === void 0 ? void 0 : _b.is_available;
        },
        anyPayoutSelected() {
            var _a, _b;
            return typeof ((_b = (_a = this.cart) === null || _a === void 0 ? void 0 : _a.payout_option) === null || _b === void 0 ? void 0 : _b.type) === 'string';
        },
        localStorePayoutSelected() {
            var _a, _b;
            return ((_b = (_a = this.cart) === null || _a === void 0 ? void 0 : _a.payout_option) === null || _b === void 0 ? void 0 : _b.type) === PayoutType.localStore;
        } }),
});
