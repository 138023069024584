import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import AddressForm from '@/components/Base/Forms/AddressForm.vue';
export default defineComponent({
    name: 'address-block',
    components: {
        AddressForm,
    },
    // AddressForm, SwitchButton, BaseEmailInput, BasePasswordInput
    data() {
        return {
            edit_address: null,
        };
    },
    methods: Object.assign(Object.assign({}, mapActions('red', {
        set_address: 'SET_ADDRESS',
    })), { finishAddressChange(changeAddress) {
            return this.set_address(changeAddress);
        } }),
    computed: Object.assign(Object.assign(Object.assign({}, mapState('red', {
        cart: 'cart',
    })), mapGetters('account', {
        isLogin: 'isLogin',
    })), { current_address: {
            get: function () {
                var _a, _b;
                return (_a = this.edit_address) !== null && _a !== void 0 ? _a : (_b = this.cart) === null || _b === void 0 ? void 0 : _b.address;
            },
            set: function (changeAddress) {
                this.edit_address = Object.assign({}, changeAddress);
                this.edit_address.id = undefined;
            },
        } }),
});
