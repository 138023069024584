import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import FormattedPrice from '@/components/Base/Formats/FormattedPrice.vue';
import { PayoutType } from '@/models/red/PayoutOption';
import { accountHolderRequiredRule, bankNameRequiredRule, emailRequiredRule, ibanRequiredRule, } from '@/helpers/ValidationRules';
import BaseEmailInput from '@/components/Base/Forms/Inputs/BaseEmailInput.vue';
import { object as validateObject } from 'yup';
import BaseInput from '@/components/Base/Forms/Inputs/BaseInput.vue';
import elementHelper from '@/helpers/documentHelper';
export default defineComponent({
    name: 'payout-conditions',
    components: { BaseInput, BaseEmailInput, FormattedPrice },
    data() {
        return {
            edit_account_holder: '',
            edit_bank: '',
            edit_iban: '',
            edit_email: '',
        };
    },
    methods: Object.assign(Object.assign({}, mapActions('red', {
        setPayoutToBankTransfer: 'SET_BANK_TRANSFER_PAYOUT',
        setPayoutToPaypal: 'SET_PAYPAL_PAYOUT',
        setPayoutToLocalStore: 'SET_LOCAL_STORE_PAYOUT',
    })), { resetEditValues() {
            this.edit_iban = '';
            this.edit_bank = '';
            this.edit_account_holder = '';
            this.edit_email = '';
        },
        resetInvalidCart() {
            const element = document.getElementById('RedPayoutOptionSelectionBox');
            if (element) {
                elementHelper.resetInvalidCartBorder(element);
            }
        },
        changePayoutTypeToBanktransfer() {
            this.resetEditValues();
            this.setPayoutToBankTransfer({ iban: '', bank: '', account_holder: '' });
            this.resetInvalidCart();
        },
        changePayoutTypeToPaypal() {
            this.resetEditValues();
            this.setPayoutToPaypal({ email: '' });
            this.resetInvalidCart();
        },
        changePayoutTypeToLocalStore() {
            this.resetEditValues();
            this.setPayoutToLocalStore({ target_location_id: null });
            this.resetInvalidCart();
        },
        saveBankTransfer() {
            var _a, _b, _c;
            if (this.edit_account_holder == '' || this.edit_bank == '' || this.edit_iban == '') {
                this.setPayoutToBankTransfer({
                    iban: this.edit_iban,
                    bank: this.edit_bank,
                    account_holder: this.edit_account_holder,
                });
                return Promise.resolve();
            }
            return this.validationSchema.fields.bankTransfer
                .validate({
                account_holder: this.edit_account_holder
                    ? this.edit_account_holder
                    : (_a = this.cart) === null || _a === void 0 ? void 0 : _a.payout_option.account_holder,
                bank: this.edit_bank ? this.edit_bank : (_b = this.cart) === null || _b === void 0 ? void 0 : _b.payout_option.bank,
                iban: this.edit_iban ? this.edit_iban : (_c = this.cart) === null || _c === void 0 ? void 0 : _c.payout_option.iban,
            })
                .then(this.setPayoutToBankTransfer);
        },
        savePaypalEmail() {
            if (this.edit_email == '') {
                this.setPayoutToPaypal({ email: '' });
                return Promise.resolve();
            }
            return this.validationSchema.fields.paypal
                .validate({
                email: this.edit_email,
            })
                .then(this.setPayoutToPaypal);
        } }),
    computed: Object.assign(Object.assign(Object.assign({}, mapState('red', {
        cart: 'cart',
        payout_options: 'payout_options',
    })), mapGetters('global', {
        isMobile: 'isMobile',
    })), { isLocalStorePayoutAvailable() {
            var _a, _b;
            return !!((_b = (_a = this.payout_options) === null || _a === void 0 ? void 0 : _a.LOCAL_STORE_PAYOUT) === null || _b === void 0 ? void 0 : _b.is_available);
        },
        isLocalStorePayoutPossible() {
            var _a, _b;
            return (_b = (_a = this.payout_options) === null || _a === void 0 ? void 0 : _a.LOCAL_STORE_PAYOUT) === null || _b === void 0 ? void 0 : _b.is_possible;
        },
        isPaypalAvailable() {
            var _a, _b;
            return !!((_b = (_a = this.payout_options) === null || _a === void 0 ? void 0 : _a.PAYPAL) === null || _b === void 0 ? void 0 : _b.is_available);
        },
        isBanktransferAvailable() {
            var _a, _b;
            return !!((_b = (_a = this.payout_options) === null || _a === void 0 ? void 0 : _a.IBAN) === null || _b === void 0 ? void 0 : _b.is_available);
        },
        validationSchema() {
            return validateObject({
                paypal: validateObject({ email: emailRequiredRule }),
                bankTransfer: validateObject({
                    account_holder: accountHolderRequiredRule,
                    bank: bankNameRequiredRule,
                    iban: ibanRequiredRule,
                }),
            });
        },
        PayoutType() {
            return PayoutType;
        }, account_holder: {
            get: function () {
                var _a;
                return this.edit_account_holder ? this.edit_account_holder : (_a = this.cart) === null || _a === void 0 ? void 0 : _a.payout_option.account_holder;
            },
            set: function (newAccountHolder) {
                this.edit_account_holder = newAccountHolder;
            },
        }, iban: {
            get: function () {
                var _a;
                return this.edit_iban ? this.edit_iban : (_a = this.cart) === null || _a === void 0 ? void 0 : _a.payout_option.iban;
            },
            set: function (newIban) {
                this.edit_iban = newIban;
            },
        }, bank_institute: {
            get: function () {
                var _a;
                return this.edit_bank ? this.edit_bank : (_a = this.cart) === null || _a === void 0 ? void 0 : _a.payout_option.bank;
            },
            set: function (newBankInstitute) {
                this.edit_bank = newBankInstitute;
            },
        }, paypal_email: {
            get: function () {
                var _a;
                return this.edit_email ? this.edit_email : (_a = this.cart) === null || _a === void 0 ? void 0 : _a.payout_option.email;
            },
            set: function (newEmail) {
                this.edit_email = newEmail;
            },
        }, current_payout_type() {
            var _a, _b;
            return (_b = (_a = this.cart) === null || _a === void 0 ? void 0 : _a.payout_option) === null || _b === void 0 ? void 0 : _b.type;
        },
        cash_total() {
            var _a, _b;
            return (_b = (_a = this.cart) === null || _a === void 0 ? void 0 : _a.cash_total) !== null && _b !== void 0 ? _b : 0.0;
        },
        local_total() {
            var _a, _b;
            return (_b = (_a = this.cart) === null || _a === void 0 ? void 0 : _a.local_store_total) !== null && _b !== void 0 ? _b : 0.0;
        },
        paypal_total() {
            var _a, _b;
            return (_b = (_a = this.cart) === null || _a === void 0 ? void 0 : _a.paypal_total) !== null && _b !== void 0 ? _b : 0.0;
        } }),
});
