import { defineComponent } from 'vue';
import openingTimesBlock from '@/components/Locations/subComponents/OpeningTimesBlock.vue';
import { locationsApiClient } from '@/services/api';
export default defineComponent({
    name: 'location-store-selected',
    emits: ['resetShopWasClicked'],
    props: {
        storeId: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            currentShop: null,
        };
    },
    components: {
        openingTimesBlock,
    },
    mounted() {
        locationsApiClient.queryLocation(this.storeId).then((location) => {
            if (!location) {
                return;
            }
            this.currentShop = location;
        });
    },
});
