var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent } from 'vue';
import LocationsMapInputFields from '@/components/Locations/LocationsMapInputFields.vue';
import GoogleMaps from '@/components/Base/Maps/Locations/GoogleMaps.vue';
import LocationCard from '@/components/Locations/subComponents/LocationCard.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import GreenGmapsClusterRenderer from '@/services/location/GreenGmapsClusterRenderer';
import LocationsByBrowserLocation from '@/components/Locations/subComponents/LocationsByBrowserLocation.vue';
import eventBus from '@/services/EventBus';
export default defineComponent({
    name: 'locations-map-store-payout-selection',
    components: {
        LocationsByBrowserLocation,
        LocationCard,
        LocationsMapInputFields,
        GoogleMaps,
    },
    data() {
        return {
            showMapsNotListing: true,
            clusterRenderer: new GreenGmapsClusterRenderer('#123645'),
            window_size_width: 0,
        };
    },
    methods: Object.assign({ storeWindowSize() {
            this.window_size_width = window.innerWidth;
        },
        storeChosen(myschubertLocationId) {
            this.setLocalStoreTargetLocation({ target_location_id: myschubertLocationId });
        } }, mapActions('red', {
        setLocalStoreTargetLocation: 'SET_LOCAL_STORE_PAYOUT',
    })),
    computed: Object.assign(Object.assign({ smallDisplay() {
            return this.window_size_width <= 1450;
        } }, mapState('global', {
        locations: 'locations',
    })), mapGetters('global', {
        isMobile: 'isMobile',
    })),
    mounted() {
        this.storeWindowSize();
        window.addEventListener('resize', this.storeWindowSize);
        eventBus.on('googleMaps:mapLocationClicked', () => __awaiter(this, void 0, void 0, function* () {
            this.showMapsNotListing = false;
        }));
    },
    unmounted() {
        window.removeEventListener('resize', this.storeWindowSize);
    },
});
