import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import CheckoutSuccessThanks from '@/components/Red/RedCheckout/components/CheckoutSuccessThanks.vue';
export default defineComponent({
    name: 'red-checkout-success-content-standard',
    components: { CheckoutSuccessThanks },
    data() {
        return {
            dhlLabelServed: this.order.shippingDocumentsStatus !== 'not_served',
        };
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    computed: Object.assign(Object.assign({}, mapGetters('account', {
        customerFirstName: 'firstName',
        customerEmail: 'email',
    })), mapGetters('global', {
        isMobile: 'isMobile',
    })),
});
