import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_base_input = _resolveComponent("base-input");
    const _component_v_date_picker = _resolveComponent("v-date-picker");
    const _component_v_dialog = _resolveComponent("v-dialog");
    return (_openBlock(), _createBlock(_component_v_dialog, {
        modelValue: _ctx.showDatepicker,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.showDatepicker) = $event)),
        "location-strategy": "connected",
        width: "auto",
        fullscreen: _ctx.showFullScreen
    }, {
        activator: _withCtx(() => [
            _createVNode(_component_base_input, {
                title: "Abholdatum",
                name: "dateInput",
                rules: _ctx.rule,
                label: _ctx.label,
                modelValue: _ctx.formattedDate,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.formattedDate) = $event)),
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.showDatepicker = true)),
                readonly: true
            }, null, 8, ["rules", "label", "modelValue"])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_v_date_picker, {
                color: "primary",
                "min-width": _ctx.showFullScreen ? '100vw' : '',
                modelValue: _ctx.currentDate,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.currentDate) = $event)),
                allowedDates: _ctx.allowedDates,
                border: _ctx.border,
                "show-adjacent-months": _ctx.showAdjacentMonths,
                label: _ctx.label,
                min: _ctx.min,
                max: _ctx.max,
                "onClick:save": _cache[3] || (_cache[3] = ($event) => (_ctx.showDatepicker = false)),
                "onClick:cancel": _cache[4] || (_cache[4] = ($event) => (_ctx.showDatepicker = false))
            }, {
                header: _withCtx(() => []),
                _: 1
            }, 8, ["min-width", "modelValue", "allowedDates", "border", "show-adjacent-months", "label", "min", "max"])
        ]),
        _: 1
    }, 8, ["modelValue", "fullscreen"]));
}
