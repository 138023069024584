import { defineComponent } from 'vue';
export default defineComponent({
    name: 'checkout-success-thanks',
    props: {
        customerFirstName: {
            type: String,
            required: true,
        },
        customerEmail: {
            type: String,
            required: true,
        },
        transactionId: {
            type: String,
            required: true,
        },
    },
});
