import { defineComponent } from 'vue';
import { PayoutType } from '@/models/red/PayoutOption';
import RedCheckoutSuccessContentStorePayout from '@/components/Red/RedCheckout/RedCheckoutSuccessContentStorePayout.vue';
import RedCheckoutSuccessContentStandard from '@/components/Red/RedCheckout/RedCheckoutSuccessContentStandard.vue';
export default defineComponent({
    name: 'red-checkout-success',
    components: {
        RedCheckoutSuccessContentStandard,
        RedCheckoutSuccessContentStorePayout,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        local_store_location: {
            type: null,
            required: false,
            default: null,
        },
    },
    computed: {
        isLocalStorePayout() {
            return this.order.payoutType === PayoutType.localStore && this.local_store_location !== null;
        },
    },
});
