export default class GreenGmapsClusterRenderer {
    constructor(hexcolor) {
        this.hexcolor = hexcolor;
    }
    render(cluster) {
        /** @see https://googlemaps.github.io/js-markerclusterer/public/renderers/ */
        const count = cluster.count;
        const position = cluster.position;
        const color = this.hexcolor;
        const svg = window.btoa(`
                           <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                            <circle cx="120" cy="120" opacity="1" r="${Math.min(count + 40, 100)}" />
                            <circle cx="120" cy="120" opacity=".15" r="${Math.min(count + 60, 120)}" />
                          </svg>`);
        return new google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(75, 75),
            },
            label: {
                text: String(count),
                color: 'rgba(255,255,255,0.9)',
                fontSize: '12px',
            },
            // adjust zIndex to be above other markers
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
    }
}
