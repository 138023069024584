import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GmapsMarker = _resolveComponent("GmapsMarker");
    const _component_MarkerCluster = _resolveComponent("MarkerCluster");
    const _component_GoogleMap = _resolveComponent("GoogleMap");
    return (_openBlock(), _createBlock(_component_GoogleMap, {
        "api-key": _ctx.key,
        style: { "width": "100%", "height": "100%" },
        zoom: 14,
        tilt: 20,
        styles: _ctx.mapsStyle,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControlPosition: 'LEFT_BOTTOM',
        ref: "mapRef"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_MarkerCluster, {
                options: _ctx.markerClusteroptions,
                ref: "clusterRef"
            }, {
                default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markerPoints, (markerPoint, i) => {
                        var _a;
                        return (_openBlock(), _createBlock(_component_GmapsMarker, {
                            options: { position: markerPoint, icon: _ctx.defaultMarkerIcon },
                            key: i,
                            ref_for: true,
                            ref: `marker-${(_a = markerPoint.id) !== null && _a !== void 0 ? _a : 0}`,
                            onClick: ($event) => { var _a; return (_ctx.sendClicked((_a = markerPoint.id) !== null && _a !== void 0 ? _a : 0)); }
                        }, null, 8, ["options", "onClick"]));
                    }), 128))
                ]),
                _: 1
            }, 8, ["options"])
        ]),
        _: 1
    }, 8, ["api-key", "styles"]));
}
