import { defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';
export default defineComponent({
    name: 'switch-toggle',
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        rules: {
            type: Object,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { value: checkValue, handleBlur: veeHandleBlur, handleChange: veeHandleChanges, resetField, meta, errorMessage, } = useField(props.name, props.rules, {
            type: 'checkbox',
            initialValue: props.modelValue,
        });
        watch(() => props.modelValue, (newValue) => {
            resetField({ value: newValue });
        });
        const handleBlur = function (event) {
            veeHandleBlur(event);
            emit('blur', event);
        };
        const handleChange = function (event, shouldValidate) {
            veeHandleChanges(event, shouldValidate);
            if (event) {
                emit('update:modelValue', event.target.checked);
            }
        };
        return {
            handleChange,
            handleBlur,
            checkValue,
            meta,
            errorMessage,
        };
    },
});
