import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0758a8ce"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "custom-control custom-switch" };
const _hoisted_2 = ["id", "name", "disabled", "readonly", "checked"];
const _hoisted_3 = ["for"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("input", {
            type: "checkbox",
            class: _normalizeClass(["custom-control-input", {
                    'is-invalid': !_ctx.meta.valid,
                    'is-valid': _ctx.meta.valid,
                    'was-validated': _ctx.meta.validated,
                }]),
            id: _ctx.id,
            name: _ctx.name,
            onInput: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
            onBlur: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
            disabled: _ctx.disabled,
            readonly: _ctx.readonly,
            checked: _ctx.checkValue
        }, null, 42, _hoisted_2),
        _createElementVNode("label", {
            class: "custom-control-label fancyCheckboxLabel w-100 font6-responsive",
            style: { "cursor": "pointer" },
            for: _ctx.id
        }, [
            _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 8, _hoisted_3)
    ]));
}
