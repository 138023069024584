import { defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';
export default defineComponent({
    name: 'checkbox-input',
    props: {
        value: {
            required: true,
        },
        id: {
            required: true,
            type: String,
        },
        name: {
            required: true,
            type: String,
        },
        text: {
            required: true,
            type: String,
        },
        modelValue: {
            required: false,
            default: false,
            type: Boolean,
        },
        rules: {
            type: Object,
            required: false,
            default: null,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { value: inputValue, meta } = useField(props.name, props.rules, {
            initialValue: props.modelValue,
        });
        const handleUpdates = (event) => {
            emit('update:modelValue', { checked: !!event, value: props.value });
        };
        watch(() => props.modelValue, (newValue) => {
            inputValue.value = newValue;
        });
        return {
            handleUpdates,
            inputValue,
            meta,
        };
    },
});
