import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_red_checkout_success_content_store_payout = _resolveComponent("red-checkout-success-content-store-payout");
    const _component_red_checkout_success_content_standard = _resolveComponent("red-checkout-success-content-standard");
    return (_ctx.isLocalStorePayout && _ctx.local_store_location)
        ? (_openBlock(), _createBlock(_component_red_checkout_success_content_store_payout, {
            key: 0,
            order: _ctx.order,
            location: _ctx.local_store_location
        }, null, 8, ["order", "location"]))
        : (_openBlock(), _createBlock(_component_red_checkout_success_content_standard, {
            key: 1,
            order: _ctx.order
        }, null, 8, ["order"]));
}
