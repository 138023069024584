import { defineComponent } from 'vue';
import FormattedPrice from '@/components/Base/Formats/FormattedPrice.vue';
import { ProductCondition } from '@/models/red/ProductEvaluation';
export default defineComponent({
    name: 'product-information',
    components: { FormattedPrice },
    props: {
        productData: {
            type: Object,
            required: true,
        },
        changeProductLink: {
            type: String,
        },
        product_price_offer: {
            type: Number,
        },
        user_evaluation: {
            type: Object,
            required: true,
        },
    },
    emits: ['change-product'],
    computed: {
        product_condition_text() {
            switch (this.user_evaluation.condition) {
                case ProductCondition.NEW:
                    return 'NEU';
                case ProductCondition.MINT:
                    return 'WIE NEU';
                case ProductCondition.VERY_GOOD:
                    return 'SEHR GUT';
                case ProductCondition.GOOD:
                    return 'GUT';
                default:
                case ProductCondition.ACCEPTABlE:
                    return 'AKZEPTABEL';
            }
        },
    },
});
