import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import FormattedPrice from '@/components/Base/Formats/FormattedPrice.vue';
import CouponUsageBlock from '@/components/Base/Coupons/CouponUsageBlock.vue';
import { CouponType } from '@/models/account/Coupon';
import eventBus from '@/services/EventBus';
import elementHelper from '@/helpers/documentHelper';
import { PayoutType } from '@/models/red/PayoutOption';
export default defineComponent({
    name: 'final-sale-completion',
    emits: [''],
    components: {
        FormattedPrice,
        CouponUsageBlock,
    },
    methods: Object.assign(Object.assign({}, mapActions('red', {
        load_coupons: 'LOAD_COUPONS',
        removeCoupon: 'REMOVE_COUPON_CODE',
        setCouponCodeToCart: 'SET_COUPON_CODE',
    })), { failConfirm() {
            var _a, _b, _c, _d;
            eventBus.emit('HideLoadingOverlay');
            const button = document.getElementById('confirm_checkout');
            if (button) {
                elementHelper.shakeElement(button);
            }
            let scrollTarget = null;
            const invalids = document.querySelectorAll('.is-invalid');
            invalids.forEach((invalid) => {
                invalid.classList.add('was-validated');
                elementHelper.shakeElement(invalid);
            });
            if (invalids.length > 0) {
                scrollTarget = invalids.item(0);
            }
            if (!((_a = this.cart) === null || _a === void 0 ? void 0 : _a.shipping_option)) {
                const element = document.getElementById('RedShippingOptionSelectionBox');
                if (element) {
                    elementHelper.shakeElement(element);
                    elementHelper.setInvalidCartBorder(element);
                    scrollTarget !== null && scrollTarget !== void 0 ? scrollTarget : (scrollTarget = element);
                }
            }
            if (!((_b = this.cart) === null || _b === void 0 ? void 0 : _b.payout_option)) {
                const element = document.getElementById('RedPayoutOptionSelectionBox');
                if (element) {
                    elementHelper.shakeElement(element);
                    elementHelper.setInvalidCartBorder(element);
                    scrollTarget !== null && scrollTarget !== void 0 ? scrollTarget : (scrollTarget = element);
                }
            }
            if (((_c = this.cart.payout_option) === null || _c === void 0 ? void 0 : _c.type) === PayoutType.localStore &&
                !((_d = this.cart.payout_option) === null || _d === void 0 ? void 0 : _d.target_location_id)) {
                eventBus.emit('openInfoPopup', {
                    icon: 'warning',
                    headline: 'Store Auswahl',
                    message: 'Bitte wähle erst noch einen Store aus!',
                });
            }
            if (!scrollTarget) {
                return;
            }
            elementHelper.scrollToTarget(scrollTarget);
        },
        confirm_sale() {
            eventBus.emit('ShowLoadingOverlay');
            if (!this.ready_for_checkout) {
                this.failConfirm();
                return;
            }
            window.location.replace('/verkaufen/checkout/finalize');
        } }),
    computed: Object.assign(Object.assign(Object.assign(Object.assign({}, mapState('red', {
        cart: 'cart',
        coupons: 'coupons',
        valid_for_checkout: 'valid_for_checkout',
    })), mapGetters('red', {
        currentCouponCode: 'currentCouponCode',
    })), mapGetters('global', {
        pendingAjaxCalls: 'hasPendingAjaxRequest',
    })), { ready_for_checkout() {
            return !(this.valid_for_checkout !== true || this.pendingAjaxCalls);
        },
        coupon_value() {
            var _a;
            return ((_a = this.cart) === null || _a === void 0 ? void 0 : _a.coupon_value) ? this.cart.coupon_value : 0;
        },
        total_payout() {
            var _a;
            return ((_a = this.cart) === null || _a === void 0 ? void 0 : _a.total) ? this.cart.total : 0;
        },
        bonus_points() {
            var _a;
            return (_a = this.cart) === null || _a === void 0 ? void 0 : _a.bonus_points;
        },
        CouponType() {
            return CouponType;
        } }),
});
