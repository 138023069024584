import { defineComponent } from 'vue';
export default defineComponent({
    name: 'condition-check',
    props: {
        current_step: {
            type: Number,
        },
        user_evaluation: {
            type: Object,
            required: true,
        },
        conditions_and_texts: {
            type: Array,
            required: true,
        },
    },
    emits: ['emitProductCondition'],
    components: {},
    data() {
        return {
            focused_condition_id: null,
        };
    },
    methods: {
        buttonFocused(id) {
            this.focused_condition_id = id;
        },
        emitProductCondition(condition) {
            this.$emit('emitProductCondition', condition);
        },
    },
});
