var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
export default defineComponent({
    name: 'locations-by-browser-location',
    props: {
        opened: {
            type: Boolean,
            required: false,
            default: false,
        },
        regionId: {
            type: null,
            required: false,
            default: null,
        },
        maxResults: {
            type: null,
            required: false,
            default: null,
        },
        localStorePayoutOnly: {
            type: null,
            required: false,
            default: null,
        },
    },
    computed: Object.assign({}, mapState('global', {
        locations: 'locations',
    })),
    methods: Object.assign(Object.assign(Object.assign({}, mapActions('global', {
        queryLocations: 'GET_LOCATIONS_BY_GEOPOS',
        indexLocations: 'LOAD_LOCATIONS',
    })), mapGetters('global', {
        getLocations: 'getLocations',
    })), { locationSearch() {
            return __awaiter(this, void 0, void 0, function* () {
                if (!('geolocation' in navigator)) {
                    // TODO : message
                    return;
                }
                navigator.geolocation.getCurrentPosition((pos) => __awaiter(this, void 0, void 0, function* () {
                    yield this.queryLocations({
                        longitude: pos.coords.longitude,
                        latitude: pos.coords.latitude,
                        opened_filter: this.opened,
                        region_id: this.regionId,
                        max_results: this.maxResults,
                        with_opening_times: false,
                        with_categories: false,
                        localStorePayoutOnly: true,
                    });
                    if (this.locations.length < 1) {
                        // no results, render full list
                        yield this.indexLocations({
                            opened_filter: this.opened,
                            region_id: this.regionId,
                            max_results: this.maxResults,
                            can_do_local_store_payout: this.localStorePayoutOnly,
                        });
                    }
                }), (err) => {
                    console.log(err);
                    // TODO: sweeter alert
                    window.alert('Standort konnte nicht ermittelt werden. Bitte nutze die manuelle Suche :(');
                });
            });
        } }),
});
