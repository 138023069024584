import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h1-responsive" };
const _hoisted_2 = { class: "font5-responsive mt-4 border-bottom border-white pb-4" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("h1", _hoisted_1, "Vielen Dank für deinen Auftrag, " + _toDisplayString(_ctx.customerFirstName) + "!", 1),
        _createElementVNode("p", _hoisted_2, [
            _createTextVNode(" Wir haben dir eine Bestätigung mit allen Informationen und Dokumenten an " + _toDisplayString(_ctx.customerEmail) + " gesendet. Deine Auftragsnummer lautet ", 1),
            _createElementVNode("strong", null, _toDisplayString(_ctx.transactionId), 1),
            _createTextVNode(" . ")
        ])
    ], 64));
}
