import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import OpeningTimesBlock from '@/components/Locations/subComponents/OpeningTimesBlock.vue';
import GoogleMapsNavigationButton from '@/components/Locations/subComponents/GoogleMapsNavigationButton.vue';
import Vue3Barcode from 'vue3-barcode';
import CheckoutSuccessThanks from '@/components/Red/RedCheckout/components/CheckoutSuccessThanks.vue';
export default defineComponent({
    name: 'red-checkout-success-content-store-payout',
    components: {
        CheckoutSuccessThanks,
        OpeningTimesBlock,
        Vue3Barcode,
        GoogleMapsNavigationButton,
    },
    props: {
        location: {
            type: Object,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
    },
    methods: {
        // https://stackoverflow.com/a/46403589
        downloadBarcode() {
            const svgEl = document.querySelector('#purchaseCode svg');
            if (!svgEl) {
                // not rendered yet
                return;
            }
            svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
            const svgData = svgEl.outerHTML;
            const preface = '<?xml version="1.0" standalone="no"?>\r\n';
            const svgBlob = new Blob([preface, svgData], { type: 'image/svg+xml;charset=utf-8' });
            const svgUrl = URL.createObjectURL(svgBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = svgUrl;
            downloadLink.download = 'Barcode.svg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
    },
    computed: Object.assign({}, mapGetters('account', {
        customerFirstName: 'firstName',
        customerEmail: 'email',
    })),
});
