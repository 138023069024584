import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-61909a7a"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["alt", "title", "src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("img", {
        class: _normalizeClass(["dudeImage", { shakeIt: _ctx.current_step === 4 }]),
        style: _normalizeStyle([{ "width": "120px", "height": "120px", "object-fit": "contain" }, _ctx.imageStyle]),
        alt: _ctx.imageAltText,
        title: _ctx.imageAltText,
        src: _ctx.imagePath
    }, null, 14, _hoisted_1));
}
