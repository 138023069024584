import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import FormattedPrice from '@/components/Base/Formats/FormattedPrice.vue';
export default defineComponent({
    name: 'small-product-overview',
    components: {
        FormattedPrice,
    },
    computed: Object.assign(Object.assign(Object.assign({}, mapState('red', {
        cart: 'cart',
    })), mapState('global', {
        serverDetectMobile: 'serverDetectMobile',
    })), { first_product() {
            var _a;
            return (_a = this.cart) === null || _a === void 0 ? void 0 : _a.products[0];
        },
        count_total() {
            var _a, _b;
            return (_b = (_a = this.cart) === null || _a === void 0 ? void 0 : _a.products.length) !== null && _b !== void 0 ? _b : 0;
        },
        total_payout() {
            var _a;
            return ((_a = this.cart) === null || _a === void 0 ? void 0 : _a.total) ? this.cart.total : 0;
        } }),
});
