import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
    class: "d-inline-block",
    style: { "min-width": "88px" }
};
const _hoisted_2 = { class: "d-inline ml-2" };
const _hoisted_3 = {
    key: 0,
    class: "font8-responsive fc-gray",
    style: { "font-style": "italic" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.dayName) + ":", 1),
        _createElementVNode("span", _hoisted_2, [
            _createTextVNode(_toDisplayString(((_a = _ctx.openingTimes) === null || _a === void 0 ? void 0 : _a.length) === 0 ? 'geschlossen' : '') + " ", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.openingTimes, (openingTime, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                    key: openingTime.id
                }, [
                    _createTextVNode(_toDisplayString(index !== 0 ? ',' : '') + " " + _toDisplayString(openingTime.time_start) + " - " + _toDisplayString(openingTime.time_end) + " ", 1),
                    (openingTime.comment)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " ( " + _toDisplayString(openingTime.comment) + " ) ", 1))
                        : _createCommentVNode("", true)
                ], 64));
            }), 128))
        ])
    ]));
}
