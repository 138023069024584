import { defineComponent } from 'vue';
export default defineComponent({
    name: 'cta-button',
    props: {
        progress_percent: {
            type: Number,
            required: true,
        },
        denied: {
            required: false,
        },
    },
    emits: ['emit-submit'],
    methods: {
        submit() {
            this.$emit('emit-submit');
        },
    },
    computed: {
        allStepsDone() {
            return this.progress_percent === 100;
        },
    },
});
