import { defineComponent } from 'vue';
import { VDatePicker } from 'vuetify/labs/components';
import dateFormatter from '@/services/formattter/Date';
import { useDisplay } from 'vuetify';
import { stringRequiredRule } from '@/helpers/ValidationRules';
import BaseInput from '@/components/Base/Forms/Inputs/BaseInput.vue';
export default defineComponent({
    name: 'date-input',
    components: {
        BaseInput,
        VDatePicker,
    },
    props: Object.assign(Object.assign({}, VDatePicker.props), { label: {
            required: true,
            type: String,
        }, valid: {
            type: Boolean,
            required: false,
            default: null,
        }, modelValue: {
            type: Object,
            required: false,
            default: null,
        } }),
    emits: ['update:modelValue'],
    data() {
        return {
            showDatepicker: false,
            currentValue: null,
        };
    },
    computed: {
        rule() {
            return stringRequiredRule.clone().default('');
        },
        showFullScreen() {
            return useDisplay().xs.value;
        },
        formattedDate: {
            get() {
                if (null === this.currentDate) {
                    return '';
                }
                return dateFormatter.format(this.currentDate);
            },
            set() { },
        },
        currentDate: {
            get() {
                var _a;
                return (_a = this.currentValue) !== null && _a !== void 0 ? _a : this.modelValue;
            },
            set(date) {
                this.currentValue = date;
                this.$emit('update:modelValue', date);
            },
        },
    },
});
