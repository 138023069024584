import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-02dcbcbb"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row mt-sm-5 mt-2 mb-3" };
const _hoisted_2 = {
    key: 0,
    class: "col-12 mb-3 px-2 font5-responsive strong",
    style: { "display": "flex", "align-items": "center" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.allStepsDone)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("button", {
                    title: "Zu Deiner Versandbox",
                    class: _normalizeClass(["buttonBase buttonGreen font6-responsive w-100", { shakeIt: _ctx.allStepsDone, disabled: _ctx.denied === true }]),
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.submit && _ctx.submit(...args)))
                }, " IN DIE VERSANDBOX ", 2)
            ]))
            : _createCommentVNode("", true)
    ]));
}
