import { defineComponent } from 'vue';
import ProductInformation from '@/components/Red/RedProductCondition/components/ProductInformation.vue';
import ProgressBar from '@/components/Red/RedProductCondition/components/ProgressBar.vue';
import ConditionCheck from '@/components/Red/RedProductCondition/components/ConditionCheck.vue';
import AccessoryCheck from '@/components/Red/RedProductCondition/components/AccessoryCheck.vue';
import CtaButton from '@/components/Red/RedProductCondition/components/CtaButton.vue';
import DudeImage from '@/components/Red/RedProductCondition/components/DudeImage.vue';
import BackButton from '@/components/Red/RedProductCondition/components/BackButton.vue';
import { mapActions } from 'vuex';
import { ProductCondition, } from '@/models/red/ProductEvaluation';
import eventBus from '@/services/EventBus';
export default defineComponent({
    name: 'red-product-condition',
    props: {
        productData: {
            type: Object,
            required: true,
        },
        conditions_and_texts: {
            type: Array,
            required: true,
        },
        replacement_id: {
            type: String,
        },
        changeProductLink: {
            type: String,
        },
    },
    emits: [],
    components: {
        ProductInformation,
        ProgressBar,
        ConditionCheck,
        AccessoryCheck,
        CtaButton,
        DudeImage,
        BackButton,
    },
    data() {
        return {
            current_step: 1,
            show_back_button: false,
            product_price_offer: undefined,
            user_evaluation: {
                condition: null,
                ovp: null,
                full_scope_of_delivery: null,
            },
            denied: null,
        };
    },
    methods: Object.assign(Object.assign({}, mapActions('red', {
        getPriceFromServer: 'FIND_PRODUCT_PRICE',
        addToCart: 'ADD_TO_CART',
    })), { openConditionInfoModal() {
            eventBus.emit('openConditionsInfoModal', this.productData.categoryId);
        },
        loadPrice() {
            if (this.server_evaluation === null) {
                return;
            }
            return this.getPriceFromServer(this.server_evaluation)
                .then((price) => {
                this.product_price_offer = price;
                this.checkForDenied();
            })
                .catch((fail) => {
                var _a, _b;
                this.product_price_offer = 0;
                this.checkForDenied((_b = (_a = fail === null || fail === void 0 ? void 0 : fail.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message);
            });
        },
        checkUserEvaluation() {
            if (this.user_evaluation.condition === ProductCondition.NEW) {
                this.user_evaluation.ovp = true;
                this.user_evaluation.full_scope_of_delivery = true;
            }
            if (this.user_evaluation.condition === ProductCondition.MINT) {
                this.user_evaluation.ovp = true;
            }
        },
        setProductCondition(value) {
            this.user_evaluation.condition = value;
            this.processInputChange();
        },
        equipmentStatus(value) {
            this.user_evaluation.full_scope_of_delivery = value;
            this.processInputChange();
        },
        processInputChange() {
            this.checkUserEvaluation();
            this.checkCurrentStep();
            this.loadPrice();
        },
        originalPackaging(value) {
            this.user_evaluation.ovp = value;
            this.processInputChange();
        },
        stepBack() {
            this.current_step = 1;
            this.user_evaluation.condition = null;
            this.user_evaluation.full_scope_of_delivery = null;
            this.user_evaluation.ovp = null;
        },
        checkCurrentStep() {
            if (this.user_evaluation.condition !== null &&
                this.user_evaluation.full_scope_of_delivery === null &&
                this.user_evaluation.ovp === null) {
                this.current_step = 2;
            }
            if (this.user_evaluation.condition !== null &&
                (this.user_evaluation.full_scope_of_delivery !== null || this.user_evaluation.ovp !== null)) {
                this.current_step = 3;
            }
            if (this.user_evaluation.condition !== null &&
                this.user_evaluation.full_scope_of_delivery !== null &&
                this.user_evaluation.ovp !== null) {
                this.current_step = 4;
            }
        },
        checkForDenied(message) {
            if (this.current_step === 4 && this.product_price_offer === 0) {
                this.denied = true;
                this.renderFailInfo(message);
                return;
            }
            this.denied = false;
        },
        renderFailInfo(message) {
            eventBus.emit('openInfoPopup', {
                icon: 'error',
                headline: 'Sorry',
                message: message !== null && message !== void 0 ? message : 'Das kaufen wir Dir leider so nicht ab!',
                timer: 4000,
            });
        },
        submit() {
            if (!this.server_evaluation) {
                return;
            }
            if (this.server_evaluation.ovp === null || this.server_evaluation.full_scope_of_delivery === null) {
                return;
            }
            this.addToCart(this.server_evaluation)
                .then(() => {
                window.location.href = '/verkaufen/versandbox';
            })
                .catch(() => {
                this.renderFailInfo('Fehler beim senden des Produkts');
            });
        } }),
    computed: {
        server_evaluation() {
            if (this.user_evaluation.condition === null) {
                return null;
            }
            return {
                product_id: this.productData.id,
                condition: this.user_evaluation.condition,
                ovp: this.user_evaluation.ovp,
                full_scope_of_delivery: this.user_evaluation.full_scope_of_delivery,
                replacement_for: this.replacement_id,
            };
        },
        progress_percent() {
            if (this.user_evaluation.condition === null) {
                return 40;
            }
            if (this.user_evaluation.ovp === null && this.user_evaluation.full_scope_of_delivery === null) {
                return 60;
            }
            if (this.user_evaluation.ovp === null || this.user_evaluation.full_scope_of_delivery === null) {
                return 80;
            }
            return 100;
        },
    },
    watch: {
        current_step: function () {
            this.show_back_button = this.current_step > 1;
        },
    },
});
