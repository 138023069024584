import { defineComponent } from 'vue';
export default defineComponent({
    name: 'progress-bar',
    props: {
        progress_percent: {
            type: Number,
            required: true,
        },
        user_evaluation: {
            type: Object,
            required: true,
        },
        current_step: {
            type: Number,
            required: true,
        },
    },
    emits: ['emit-step-back-link'],
    methods: {
        stepBackLink() {
            this.$emit('emit-step-back-link');
        },
    },
});
