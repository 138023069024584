import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b05a280"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "col-12 strong p-0" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["stickyCtaRow container-fluid p-2", [
                {
                    hidden: _ctx.target_in_viewport,
                    'fade-in': !_ctx.target_in_viewport,
                },
                _ctx.additional_classes,
            ]])
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("a", {
                href: "#",
                class: "buttonBase buttonGreen strong p-2 font5-responsive w-100",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
                //@ts-ignore
                (...args) => (_ctx.clickStickyCta && _ctx.clickStickyCta(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.button_text), 1)
        ])
    ], 2));
}
