import { defineComponent } from 'vue';
import BaseInput from '@/components/Base/Forms/Inputs/BaseInput.vue';
import { mapActions, mapState } from 'vuex';
import { postCodeRequiredRule } from '@/helpers/ValidationRules';
import CheckboxInput from '@/components/Base/Forms/Inputs/CheckboxInput.vue';
export default defineComponent({
    name: 'locations-map-input-fields',
    components: {
        CheckboxInput,
        BaseInput,
    },
    data() {
        return {
            opened: false,
            searchString: '',
        };
    },
    emits: ['showList'],
    methods: Object.assign({ checkboxChanged(status) {
            // dispatch action to query for locations with filter opened / all
            // check for current Region, apply that if needed
            // honor searchstring
            this.opened = status.checked;
            this.refreshLocations();
        },
        searchInput(event) {
            // check for current Region, apply that if needed
            // honor opened info
            if (!event.valid) {
                return;
            }
            this.searchString = event.value;
            this.refreshLocations();
        },
        refreshLocations() {
            var _a;
            const params = {
                opened_filter: this.opened,
                region_id: (_a = this.currentRegion) === null || _a === void 0 ? void 0 : _a.id,
            };
            if (this.searchString) {
                const searchParams = Object.assign(Object.assign({}, params), { search_string: this.searchString });
                if (this.checkForZipCodeIncomplete()) {
                    return;
                }
                this.searchLocations(searchParams);
                this.$emit('showList', true);
                return;
            }
            this.indexLocations(params);
        },
        /** temporary, input needs better validation and event dispatching */
        checkForZipCodeIncomplete() {
            /** try to detect a zipcode input, wait until input is completed */
            return this.searchString.match(/^\d{1,4}$/) !== null;
        } }, mapActions('global', {
        searchLocations: 'SEARCH_LOCATIONS',
        indexLocations: 'LOAD_LOCATIONS',
    })),
    computed: Object.assign({ rule() {
            return postCodeRequiredRule.clone().default('');
        } }, mapState('global', { currentRegion: 'currentRegion' })),
});
