import { defineComponent } from 'vue';
export default defineComponent({
    name: 'opening-time-per-day',
    props: {
        dayName: {
            type: String,
            required: true,
        },
        // https://github.com/vuejs/core/issues/3948#issuecomment-860466204
        openingTimes: {
            required: true,
            type: null,
            validator: (v) => typeof v === 'object' || v === null,
        },
    },
});
